import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserListActionCreator } from "../../redux/slices/UserSlices";
import { IoIosMore } from "react-icons/io";

import EditIcon from "../../assets/icons/edit-icon.svg";
import ResetPasswordIcon from "../../assets/icons/reset-password-icon.svg";
import DeactivateIcon from "../../assets/icons/deactivate-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";

import Table from "../../components/table/Table";
import Button from "../../components/button/Button";
import CustomModal from "../../components/customModal/CustomModal";
import UserForm from "./UserForm";
import Loader from "../../components/loader/Loader";
import ConfirmModal from "./ConfirmModal";
import ResetPasswordModal from "./ResetPasswordModal";
import style from "./UserList.module.scss";

const UserList = () => {
  const dispatch = useDispatch();
  const [modalView, setModalView] = useState(null);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmationType, setConfirmationType] = useState(null);
  const [openPopOver, setOpenPopOver] = useState("");
  const [isPwdModalOpen, setIsPwdModalOpen] = useState(false);

  const userList = useSelector((state) => state.user.userList);
  const isLoading = useSelector((state) => state.user.loading);

  const popoverRef = useRef();

  const calculatePopoverPosition = (triggerElement) => {
    const rect = triggerElement.getBoundingClientRect();
    const popoverHeight = 216;

    const spaceAbove = rect.top;
    const spaceBelow = window.innerHeight - rect.bottom - 50;

    let position = "bottom"; // Default position

    if (spaceBelow < popoverHeight && spaceAbove > popoverHeight) {
      position = "top";
    }

    return position;
  };

  const handlePopoverOpen = (userData, event) => {
    const position = calculatePopoverPosition(event.currentTarget);
    setOpenPopOver({ data: userData?.row?.original, position });
  };

  useEffect(() => {
    const handleClickOutsidePopover = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setOpenPopOver("");
      }
    };

    if (openPopOver) {
      document.addEventListener("mousedown", handleClickOutsidePopover);
    } else {
      document.removeEventListener("mousedown", handleClickOutsidePopover);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePopover);
    };
  }, [openPopOver]);

  const getUserList = () => {
    dispatch(getUserListActionCreator());
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StatusCell = ({ value }) => {
    return (
      <div
        className={`${style.statusCell} ${value === true ? style.active : style.inactive}`}
      >
        {value === true ? <span>Active</span> : <span>Inactive</span>}
      </div>
    );
  };

  const NameCell = ({ value }) => {
    return (
      <div>
        {value && value != "" ? <span>{value}</span> : <span>{`-`}</span>}
      </div>
    );
  };

  const RoleCell = ({ value }) => {
    return (
      <div className={style.roleCell}>
        {value === true ? <span>Administrator</span> : <span>Standard</span>}
      </div>
    );
  };

  const MfaCell = ({ value }) => {
    return (
      <div
        className={`${style.statusCell} ${value === true ? style.active : style.inactive}`}
      >
        {value === true ? <span>Enabled</span> : <span>Disabled</span>}
      </div>
    );
  };

  const handleRowEdit = (userData) => {
    setModalView("editView");
    setEditData(userData?.row?.original);
    setShowModal(!showModal);
  };

  const handleDeleteRow = (userData) => {
    setEditData(userData?.row?.original);
    setConfirmationType("delete");
    setShowModal(!showModal);
  };

  const handleDeactivateUser = (userData) => {
    const updatedUserData = {
      id: userData?.row?.original.id,
      body: {
        ...userData?.row?.original,
        is_active: !userData?.row?.original.is_active,
      },
    };
    setEditData(updatedUserData);
    setConfirmationType("deactivate");
    setShowModal(!showModal);
  };

  const handleDisableMFA = (userData) => {
    setEditData(userData?.row?.original);
    setConfirmationType("disableMFA");
    setShowModal(!showModal);
  };

  const handleResetPassword = (userData) => {
    setIsPwdModalOpen(true);
    setEditData(userData?.row?.original);
  };

  const icons = (userData) => (
    <div className={style.iconWrapper}>
      <div className={style.iconSeparator}></div>
      <button
        className={style.iconBtn}
        onClick={(event) => handlePopoverOpen(userData, event)}
      >
        <IoIosMore />
      </button>
      {openPopOver.data === userData?.row?.original && (
        <div
          className={`${style.popOver} ${style[openPopOver.position]}`}
          ref={popoverRef}
        >
          <ul className={style.popList}>
            <li onClick={() => handleRowEdit(userData)}>
              <img src={EditIcon} alt="Edit Icon" />
              <span>Edit User</span>
            </li>
            <li onClick={() => handleResetPassword(userData)}>
              <img src={ResetPasswordIcon} alt="Reset Password Icon" />
              <span>Reset Password</span>
            </li>
            <li onClick={() => handleDeactivateUser(userData)}>
              <img src={DeactivateIcon} alt="Deactivate Icon" />
              <span>
                {!userData?.row?.original.is_active
                  ? "Activate User"
                  : "Deactivate User"}
              </span>
            </li>
            <li onClick={() => handleDeleteRow(userData)}>
              <img src={DeleteIcon} alt="Delete Icon" />
              <span>Delete User</span>
            </li>
            {userData?.row?.original.mfa_enabled && (
              <li onClick={() => handleDisableMFA(userData)}>
                <img src={DeleteIcon} alt="Delete Icon" />
                <span>Disable MFA</span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );

  const columns = [
    // {
    //   Header: "Id",
    //   accessor: "id",
    // },
    {
      Header: "First Name",
      accessor: "first_name",
      Cell: NameCell,
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      Cell: NameCell,
    },
    {
      Header: "User Type",
      accessor: "is_superuser",
      Aggregated: ({ value }) =>
        `${value ? "Administrator" : "Standard"} Roles`,
      Cell: RoleCell,
      filter: "dropdown",
      options: [
        { label: "Administrator", value: true },
        { label: "Standard", value: false },
      ],
      sortType: (rowA, rowB) => {
        return rowA.original.is_superuser === rowB.original.is_superuser
          ? 0
          : rowA.original.is_superuser
            ? -1
            : 1;
      },
    },
    {
      Header: "User Name",
      accessor: "username",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "is_active",
      Aggregated: ({ value }) => `${value ? "Active" : "Inactive"} Status`,
      Cell: StatusCell,
      filter: "dropdown",
      options: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
      sortType: (rowA, rowB) => {
        return rowA.original.is_active === rowB.original.is_active
          ? 0
          : rowA.original.is_active
            ? -1
            : 1;
      },
    },
    {
      Header: "MFA Status",
      accessor: "mfa_enabled",
      Aggregated: ({ value }) => `${value ? "Enabled" : "Disabled"} MFA`,
      Cell: MfaCell,
      filter: "dropdown",
      options: [
        { label: "Enabled", value: true },
        { label: "Disabled", value: false },
      ],
      sortType: (rowA, rowB) => {
        return rowA.original.mfa_enabled === rowB.original.mfa_enabled
          ? 0
          : rowA.original.mfa_enabled
            ? -1
            : 1;
      },
    },
    {
      Header: "",
      accessor: "actions",
      Cell: icons,
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const showPopup = () => {
    setShowModal(!showModal);
    setModalView("addView");
  };

  const closePopup = () => {
    setShowModal(false);
    getUserList();
    setConfirmationType(null);
    setIsPwdModalOpen(false);
  };

  const getModalTitle = () => {
    if (confirmationType === "deactivate") {
      return editData?.row?.original.is_active
        ? "Activate User"
        : "Deactivate User";
    }
    if (confirmationType === "delete") return "Delete User";
    if (isPwdModalOpen) return "Reset Password";
    if (modalView === "editView") return "Edit User";
    if (modalView === "addView") return "Create User";
    return "";
  };

  return (
    <>
      <Loader show={isLoading} />
      <div className={style.userList}>
        <div className={style.addUser}>
          <Button text="Add User" clickFunction={showPopup} />
        </div>
        <Table columns={columns} showPaginations={false} dataList={userList} />
        {(showModal || isPwdModalOpen) && (
          <CustomModal
            title={getModalTitle()}
            clickFunction={closePopup}
            additionalClass={(modalView) ? style.userPopup : ''}
            body={
              isPwdModalOpen ? (
                <ResetPasswordModal
                  closePopup={closePopup}
                  userData={editData}
                />
              ) : confirmationType ? (
                <ConfirmModal
                  closePopup={closePopup}
                  confirmationType={confirmationType}
                  userData={editData}
                />
              ) : (
                <UserForm
                  closePopup={closePopup}
                  modalView={modalView}
                  editData={editData}
                />
              )
            }
          />
        )}
      </div>
    </>
  );
};

export default UserList;
