import React, { Suspense, useEffect } from "react";
import { commonRoutes, routes, authroutes } from "./route";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import Loader from "./components/loader/Loader";
import AccessDenied from "./pages/auth/access-denied/AccessDenied";
import Header from "./layouts/header/Header";
import NotFound from "./pages/NotFound";
import InjectAxiosInterceptors from "./components/injectAxiosInterceptor/InjectAxiosInterceptors";
import Login from "./pages/auth/login/Login";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import SkipLink from "./components/skipLink/SkipLink";
import "./App.scss";

function App() {
  const location = useLocation();
  const userProfile = useSelector((state) => state.login.userProfile);
  const loginToken = useSelector((state) => state.login.loginDetail);
  // const isLoading = useSelector((state) => state.login.loading);
  useEffect(() => {}, [location.pathname]);
  const publicRoutes = (route) => {
    return route.map(({ path, component }) => {
      if (path !== "/login") {
        return <Route key={path} path={path} element={component} exact />;
      }
    });
  };
  const authRoutes = (route) => {
    return route.map(({ path, component, isLogged }) => {
      if (!isLogged) {
        return <Route key={path} path={path} element={component} exact />;
      }
    });
  };
  const privateRoutes = (route) => {
    let profile = JSON.parse(JSON.stringify(userProfile)) || {};
    return route.map(({ path, component }) => {
      if (profile?.is_superuser) {
        return <Route key={path} path={path} element={component} exact />;
      } else {
        return (
          <Route key={path} path={path} element={<AccessDenied />} exact />
        );
      }
    });
  };
  useEffect(() => {}, []);

  return (
    <div className="App">
      <InjectAxiosInterceptors />

      {/* Add SkipLink component */}
      <SkipLink />

      <Suspense fallback={"loading..."}>
        {/* <Loader show={isLoading} /> */}
        {userProfile && <Header />}
        <main id="mainContent">
          <Routes>
            {userProfile && (
              <>
                {publicRoutes(commonRoutes)}
                <Route
                  path="/update-password"
                  element={<ChangePassword />}
                  exact
                />
              </>
            )}
            <Route
              path="/login"
              element={userProfile ? <Navigate to="/" /> : <Login />}
            />
            {userProfile ? (
              <>{privateRoutes(routes)}</>
            ) : (
              <>
                <Route path="*" element={<Navigate to="/login" />} />
                {authRoutes(authroutes)}
                {loginToken && (
                  <Route
                    path="/update-password"
                    element={<ChangePassword />}
                    exact
                  />
                )}
              </>
            )}
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </Suspense>
    </div>
  );
}

export default App;
