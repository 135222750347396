import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Button from "../../components/button/Button";
import CustomModal from "../../components/customModal/CustomModal";
import style from "./Dashboard.module.scss";

const UpdatePasswordAlert = ({ navigation }) => {
  return (
    <div>
      <h4 className={style.alertMsg}>
        To secure your account, please create a new password before proceeding.
      </h4>
      <div className={style.confirmBtns}>
        <Button text={"Update"} clickFunction={navigation} />
      </div>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const loginInfo = useSelector((state) => state.login.loginDetail);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (loginInfo?.is_new && !JSON.parse(Cookies.get("skip_alert"))) {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePassword = () => {
    setShowModal(false);
    navigate("/update-password");
  };
  const closePopup = () => {
    setShowModal(false);
    Cookies.set("skip_alert", true);
  };
  return (
    <div>
      Dashboard
      <div>
        {showModal && (
          <CustomModal
            title={"Alert"}
            clickFunction={closePopup}
            body={
              <>
                <UpdatePasswordAlert navigation={updatePassword} />
              </>
            }
          />
        )}
      </div>
    </div>
  );
};
export default Dashboard;
