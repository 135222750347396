import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Success from "../../assets/icons/success.svg";
import Error from "../../assets/icons/error.svg";
import Toast from "../toast/Toast";
import ToastContent from "../toast/ToastContent";
import { logoutActionCreator } from "../../redux/slices/LoginSlices";

function InjectAxiosInterceptors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (response.config.method.toUpperCase() !== "GET") {
          let toastrMsg = "";
          if (response.config.method.toUpperCase() === "PUT") {
            toastrMsg = response.data.detail;
          } else if (response.config.method.toUpperCase() === "DELETE") {
            toastrMsg = "Deleted Successfully";
          } else {
            toastrMsg = response.data?.message
              ? response.data.message
              : "Success";
          }
          if (!isLoginPage) {
            toast.success(
              <ToastContent
                title="Success"
                description={
                  response.request.responseURL.includes("/login")
                    ? "Login Successful!"
                    : toastrMsg
                }
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Success} />,
              },
            );
          }
        }
        return response;
      },
      (errorRes) => {
        // Don't show error toasts on login page
        if (errorRes?.code == "ERR_NETWORK") {
          toast.error(
            <ToastContent
              title="Error"
              description={
                "Network connection lost. Please check your connection and try again."
              }
            />,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              icon: () => <img src={Error} />,
            },
          );
        } else {
          let errorMessage = "An error occurred";
          if (!isLoginPage) {
            console.log("err", errorRes.response);
            if (errorRes.response?.data?.message) {
              errorMessage = errorRes.response.data.message;

              // If the message is an object, get the first error message
              if (typeof errorMessage === "object") {
                errorMessage = errorMessage[Object.keys(errorMessage)[0]];
              }
            } else if (
              errorRes.response?.data &&
              typeof errorRes.response?.data === "object"
            ) {
              errorMessage = errorRes.response.data;
              errorMessage = errorMessage[Object.keys(errorMessage)[0]];
            }
          }
          // Redirect to session expired page on 401 errors
          if (
            errorRes.response?.status === 401 &&
            errorRes?.response?.data?.code == "token_not_valid"
          ) {
            const sessionExpire = {
              state: {
                message: "Your session has expired due to inactivity",
              },
            };
            toast.error(
              <ToastContent
                title="Error"
                description={"Your session has expired due to inactivity"}
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Error} />,
              },
            );
            dispatch(logoutActionCreator())
              .then(() => navigate("/login", sessionExpire))
              .catch(() => navigate("/login", sessionExpire));
            return;
          }
          if (
            !isLoginPage &&
            errorRes?.response?.data?.code !== "token_not_valid"
          ) {
            toast.error(
              <ToastContent
                title="Error"
                description={
                  errorRes.response?.status === 401
                    ? "Your session has expired due to inactivity"
                    : errorMessage
                }
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Error} />,
              },
            );
          }
        }
        return Promise.reject(errorRes);
      },
    );

    // Cleanup function to eject the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate, isLoginPage, dispatch]);

  return <Toast />;
}

export default InjectAxiosInterceptors;
