import { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { LuLogOut } from "react-icons/lu";
import {
  logoutActionCreator,
  getUserDetailActionCreator,
} from "../../redux/slices/LoginSlices";
import logo from "../../assets/logo/hines.svg";
import style from "./Header.module.scss";
import { CgProfile } from "react-icons/cg";

const Header = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.login.userProfile);
  const token = useSelector((state) => state.login.loginDetail);
  const [openPopOver, setOpenPopOver] = useState(false);
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const Logout = () => {
    if (Cookies.get("token")) {
      dispatch(
        logoutActionCreator({
          body: {
            refresh: token?.refresh,
          },
        }),
      ).then(() => {
        navigate("/login");
      });
    } else {
      navigate(0);
    }
  };

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenPopOver(false);
    }
  };

  let profile = JSON.parse(JSON.stringify(userProfile)) || {};

  const togglePopOver = () => {
    setOpenPopOver(!openPopOver);
  };

  const fetchUser = async () => {
    const response = await dispatch(
      getUserDetailActionCreator(Cookies.get("token")),
    );
    if (!response.error) {
      navigate("/profile", { state: { user: response.payload } });
      setOpenPopOver(false);
    } else {
      console.log(response);
    }
  };

  return (
    <div className={style.header}>
      <div className={style.innerContent}>
        <div className={style.left}>
          <div onClick={() => navigate("/")} className={style.logoContainer}>
            <img src={logo} alt="hines logo" className={style.logo} />
          </div>
          <ul className={style.navLink}>
            <Link
              to={"/properties"}
              className={`${style.navItems} ${location.pathname == "/properties" ? style.active : ""} disable`}
            >
              <li>Properties</li>
            </Link>
            <Link
              to={"/budget"}
              className={`${style.navItems} ${location.pathname == "/budget" ? style.active : ""} disable`}
            >
              <li>Budget</li>
            </Link>
            <Link
              to={"/products"}
              className={`${style.navItems} ${location.pathname == "/products" ? style.active : ""} disable`}
            >
              <li>Product</li>
            </Link>
            {profile?.is_superuser && (
              <Link
                to={"/user"}
                className={`${style.navItems} ${location.pathname == "/user" ? style.active : ""} `}
              >
                <li>Users</li>
              </Link>
            )}
          </ul>
        </div>
        <div className={style.right}>
          <button
            className={style.userLogo}
            ref={buttonRef}
            role="button"
            onClick={togglePopOver}
          >
            {userProfile?.first_name
              ? userProfile?.first_name[0].toUpperCase()
              : userProfile?.username[0].toUpperCase()}
          </button>
          {openPopOver && (
            <div className={style.popOver} ref={popoverRef}>
              <ul className={style.popList}>
                <button onClick={fetchUser} className={style.profile}>
                  <li>
                    <CgProfile />
                    Profile
                  </li>
                </button>
                <button onClick={Logout}>
                  <li>
                    <LuLogOut /> <span>Logout</span>
                  </li>
                </button>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
