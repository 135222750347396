import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  userList: [],
  userDetails: null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
};

export const getUserListActionCreator = createAsyncThunk(
  "user/list",
  async () => {
    let API_URL = `${SERVER_URL.USER_LIST}`;
    const { data } = await fetchUtility("get", `${API_URL}`);
    return data;
  },
);

// export const saveUserActionCreator = createAsyncThunk(
//   "user/register",
//   async (params) => {
//     let API_URL = `${SERVER_URL.CREATE_USER}`;
//     const { data } = await fetchUtility("post", `${API_URL}`, params.body);
//     return data;
//   },
// );

export const saveUserActionCreator = createAsyncThunk(
  "user/register",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_USER}`;

    try {
      const { data } = await fetchUtility("post", `${API_URL}`, params.body);
      return data; // If the request is successful, return the data
    } catch (error) {
      if (error.response?.data?.username?.[0]) {
        return rejectWithValue(
          error.response?.data?.username?.[0] ||
            "This username is already taken.",
        );
      } else if (error.response?.data?.email?.[0]) {
        return rejectWithValue(
          error.response?.data?.email?.[0] || "This email is already taken.",
        );
      } else {
        return rejectWithValue(
          error.message || "An error occurred while creating the user",
        );
      }
    }
  },
);

export const updateProfileCreator = createAsyncThunk(
  "users/profile/update/",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.UPDATE_PROFILE}`;
      const { data } = await fetchUtility(
        "put",
        `${API_URL}`,
        params.body,
      );
      return data;
    } catch(error) {
      if (error.response?.data?.username?.[0]) {
        return rejectWithValue(
          error.response?.data?.username?.[0] ||
            "This username is already taken.",
        );
      } else if (error.response?.data?.email?.[0]) {
        return rejectWithValue(
          error.response?.data?.email?.[0] || "This email is already taken.",
        );
      } else {
        return rejectWithValue(
          error.message || "An error occurred while creating the user",
        );
      }
    }
  }
)

export const updateUserActionCreator = createAsyncThunk(
  "users/update",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.UPDATE_USER}`;
      const { data } = await fetchUtility(
        "put",
        `${API_URL}${params.body.id}/`,
        params.body,
      );
      return data;
    } catch (error) {
      if (error.response?.data?.password?.[0]) {
        return rejectWithValue("Password cannot be empty");
      } else if (error.response?.data?.username?.[0]) {
        return rejectWithValue(
          error.response?.data?.username?.[0] ||
            "This username is already taken.",
        );
      } else if (error.response?.data?.email?.[0]) {
        return rejectWithValue(
          error.response?.data?.email?.[0] || "This email is already taken.",
        );
      } else {
        return rejectWithValue(
          error.message || "An error occurred while creating the user",
        );
      }
    }
  },
);

export const deleteUserActionCreator = createAsyncThunk(
  "user/delete",
  async (id) => {
    let API_URL = `${SERVER_URL.DELETE_USER}`;
    const { data } = await fetchUtility("delete", `${API_URL}${id}/`);
    return data;
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    //user List
    builder.addCase(getUserListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.userList = action.payload;
      state.error = "";
    });
    builder.addCase(getUserListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //create new user
    builder.addCase(saveUserActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveUserActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveUserActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //update user
    builder.addCase(updateUserActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateUserActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //delete user
    builder.addCase(deleteUserActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteUserActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export default userSlice.reducer;
