import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  loginDetail: Cookies.get("token") ? JSON.parse(Cookies.get("token")) : null,
  userProfile: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
  mfaError: "",
};

export const getLoginActionCreator = createAsyncThunk(
  "users/login",
  async (params) => {
    let API_URL = `${SERVER_URL.LOGIN}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
    );
    return data;
  },
);

export const getUserDetailActionCreator = createAsyncThunk(
  "users/profile",
  async () => {
    let API_URL = `${SERVER_URL.USER_PROFILE}`;
    const { data } = await fetchUtility("get", `${API_URL}`);
    return data;
  },
);

export const getMFAStatusActionCreator = createAsyncThunk(
  "users/mfa/status",
  async (params) => {
    let API_URL = `${SERVER_URL.MFA_STATUS}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
    );
    return data;
  },
);

export const registerMFAActionCreator = createAsyncThunk(
  "users/mfa/register",
  async (params) => {
    let API_URL = `${SERVER_URL.MFA_REGISTER}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
    );
    return data;
  },
);

export const verifyMFAActionCreator = createAsyncThunk(
  "users/mfa/verify",
  async (params) => {
    let API_URL = `${SERVER_URL.MFA_VERIFY}`;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}`,
      params.body,
      false,
    );
    return data;
  },
);

export const removeMFAActionCreator = createAsyncThunk(
  "users/mfa/disable",
  async (id) => {
    let API_URL = `${SERVER_URL.MFA_REMOVE}`;
    const { data } = await fetchUtility("post", `${API_URL}${id}/`);
    return data;
  },
);

export const logoutActionCreator = createAsyncThunk(
  "users/logout",
  async (params) => {
    let API_URL = `${SERVER_URL.LOGOUT}`;
    const { data } = await fetchUtility("post", `${API_URL}`, params.body);
    return data;
  },
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    /*login api handler*/
    builder.addCase(getLoginActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoginActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.loginDetail = action.payload;
      state.error = "";
      const encryptedLoginDetails = state?.loginDetail;
      Cookies.set("token", JSON.stringify(encryptedLoginDetails));
    });
    builder.addCase(getLoginActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /*user profile api handler*/
    builder.addCase(getUserDetailActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetailActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.userProfile = action.payload;
      state.error = "";
      const encryptedUserDetails = state?.userProfile;
      Cookies.set("user", JSON.stringify(encryptedUserDetails));
    });
    builder.addCase(getUserDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    /*mfa api handler*/
    builder.addCase(getMFAStatusActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMFAStatusActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getMFAStatusActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(registerMFAActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(registerMFAActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.mfaError = "";
    });
    builder.addCase(registerMFAActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.mfaError = action.error.message;
    });
    builder.addCase(verifyMFAActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyMFAActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.loginDetail = action.payload;
      state.mfaError = "";
      const encryptedUserDetails = state?.loginDetail;
      Cookies.set("token", JSON.stringify(encryptedUserDetails));
    });
    builder.addCase(verifyMFAActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.mfaError = action.error.message;
    });
    //logout
    builder.addCase(logoutActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutActionCreator.fulfilled, (state) => {
      Cookies.remove("user");
      Cookies.remove("token");
      Cookies.remove("skip_alert");
      state.loginDetail = null;
      state.userProfile = null;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(logoutActionCreator.rejected, (state, action) => {
      Cookies.remove("user");
      Cookies.remove("token");
      Cookies.remove("skip_alert");
      state.loading = false;
      state.error = action.error.message;
      state.loginDetail = null;
      state.userProfile = null;
    });
  },
});

export default loginSlice.reducer;
