import Select from "react-select";
import "./InputField.scss";
const SelectField = ({
  name,
  label,
  option,
  isDisabled,
  field,
  onChange,
  errors,
  ariaLabel,
}) => {
  return (
    <>
      {label && <label className="label">{label}</label>}
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={option[0]}
        isDisabled={isDisabled}
        name={name}
        options={option}
        {...field}
        value={field?.field?.value}
        onChange={onChange}
        isSearchable={false}
        ariaLabel={ariaLabel}
      />
      {errors && (
        <p className="error">
          {errors?.message ? errors?.message : `${name} is ${errors.type}`}
        </p>
      )}
    </>
  );
};
export default SelectField;
