import { createPortal } from "react-dom";
import { IoClose } from "react-icons/io5";
import "./CustomModal.scss";

const CustomModal = ({ title, additionalClass, clickFunction, body }) => {
  return createPortal(
    <div className="modal">
      <div className={`modal-content ${additionalClass}`}>
        {title && <h2 className="modal-title">{title}</h2>}
        <button className="close" onClick={clickFunction}>
          <IoClose />
        </button>
        {body}
      </div>
    </div>,
    document.body,
  );
};

export default CustomModal;
