import axios from "axios";
import Cookie from "js-cookie";

const fetchUtility = async (
  method,
  url,
  payload = {},
  enableAuthorizationHeader = true,
  headers = {},
  options = {},
) => {
  try {
    let defaultTimeout = 20000;
    return axios({
      method,
      url,
      data: payload,
      headers: {
        Authorization: enableAuthorizationHeader
          ? `Bearer ${JSON.parse(Cookie.get("token"))["access"]}`
          : "",
        ...headers,
      },
      timeout: options.timeout || defaultTimeout, // Set timeout here
      ...options,
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
      // Handle timeout error specifically
      console.error("Request timed out:", error);
      throw new Error("Request timed out. Please try again.");
    } else {
      // Handle other types of errors
      console.error("API call failed:", error);
      throw error;
    }
  }
};

export default fetchUtility;
