import "./LeftImageBlock.scss";

const LeftImageBlock = () => {
  return (
    <div className="imageSection">
      <div className="loginImage">
        <h1 className="imageHeading">Why Retail Now?</h1>
        <p className="imageContent">
          The evolution of retail, spurred by e-commerce growth and shifting
          consumer preferences toward experiential shopping. This shift offers
          real estate investors opportunities in mixed-use developments and
          urban, community-focused locations.
        </p>
      </div>
    </div>
  );
};

export default LeftImageBlock;
