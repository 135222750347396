import { configureStore } from "@reduxjs/toolkit";
import LoginSlices from "./slices/LoginSlices";
import UserSlices from "./slices/UserSlices";
import ChangePasswordSlices from "./slices/ChangePasswordSlices";

const store = configureStore({
  reducer: {
    login: LoginSlices,
    user: UserSlices,
    changePassword: ChangePasswordSlices,
  },
});
export default store;
