import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import InputFields from "../../../components/inputFields/InputFields";
import Button from "../../../components/button/Button";
import { forgotPasswordActionCreator } from "../../../redux/slices/ForgotPasswordSlices";
import { verifyMFAActionCreator } from "../../../redux/slices/LoginSlices"; // Import MFA action if needed
import style from "./ForgotPassword.module.scss";
import { QRCodeSVG } from "qrcode.react"; // If you need to show a QR code

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [showMFA, setShowMFA] = useState(false); // State to show the MFA section
  const [qrCode] = useState(""); // If you want to show a QR code
  // const [qrCode, setQrCode] = useState(""); // If you want to show a QR code
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      mfaCode: "",
    },
  });

  const onSubmitUsername = async (data) => {
    // Dispatch the forgotPassword action creator with the username
    const resultAction = await dispatch(
      forgotPasswordActionCreator({ body: { username: data.username } }),
    );

    // Check if the action was fulfilled and handle showing MFA
    if (forgotPasswordActionCreator.fulfilled.match(resultAction)) {
      // Show MFA input section
      setShowMFA(true);
      // If you need to generate a QR code or get an MFA URI, you can do that here
      // Example: setQrCode(resultAction.payload.mfa_uri);
    }
  };

  const onSubmitMFA = async (data) => {
    // Handle MFA verification here, if necessary
    const resultAction = await dispatch(
      verifyMFAActionCreator({
        body: {
          username: getValues("username"),
          otp_code: data.mfaCode,
        },
      }),
    );

    // Handle the result of the MFA verification (e.g., redirect, show message)
    if (verifyMFAActionCreator.fulfilled.match(resultAction)) {
      // Proceed with next steps, like navigating to a different screen
      console.log("MFA verified successfully");
    }
  };

  return (
    <div className={style.forgotPassword}>
      <h2>Forgot Password</h2>
      {!showMFA ? (
        <form onSubmit={handleSubmit(onSubmitUsername)}>
          <Controller
            control={control}
            name="username"
            rules={{ required: "Username is required" }}
            render={({ field }) => (
              <InputFields
                {...field}
                type="text"
                label="Username"
                error={errors.username}
                title="Enter the username"
              />
            )}
          />
          <div className={style.submitBtn}>
            <Button text="Submit" />
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit(onSubmitMFA)}>
          {qrCode && (
            <div className="qrcode__image">
              <p>Please scan the QR code with your authenticator app:</p>
              <QRCodeSVG value={qrCode} size={130} />
            </div>
          )}
          <Controller
            control={control}
            name="mfaCode"
            rules={{
              required: "Verification code is required",
              pattern: {
                value: /^[0-9]+$/,
                message: "Only digits are allowed",
              },
              minLength: {
                value: 6,
                message: "Minimum 6 digits required",
              },
              maxLength: {
                value: 6,
                message: "Maximum 6 digits allowed",
              },
            }}
            render={({ field }) => (
              <InputFields
                {...field}
                type="text"
                label="Verification Code"
                error={errors.mfaCode}
                title="Enter the verfication code"
              />
            )}
          />
          <div className={style.submitBtn}>
            <Button text="Verify" />
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
