import React, { useState, useEffect } from 'react';
// import { useDispatch } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import styles from './UserProfile.module.scss';
import Button from '../../components/button/Button';
import { CgProfile } from "react-icons/cg";
import Loader from "../../components/loader/Loader";
import CustomModal from "../../components/customModal/CustomModal";
import UserForm from './UserForm';
import { getUserDetailActionCreator } from "../../redux/slices/LoginSlices";
import ResetPassword from '../auth/changePassword/ResetPassword';

const UserProfile = () => {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isReset, setResetPassword] = useState(false);
  const [formData, setFormData] = useState(null);

  const [loading, setLoading] = useState(true);   
  const isLoading = useSelector((state) => state.user.loading) || loading;

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    setLoading(true); 
    const response = await dispatch(getUserDetailActionCreator(Cookies.get("token")));
    if (!response.error) {
      setFormData(response.payload);
    } else {
      console.log(response);
    }
    setLoading(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  const handleCloseReset = () => {
    setResetPassword(false);
  }

  const handleUpdateUser = (updatedData) => {
    setFormData(updatedData);
    handleCloseModal();
  };

  const handleUpdatePassword = () => {
    handleCloseReset();
  }

  const handleResetPassword = () => {
    setResetPassword(true);
  };

  return (
    <>
      <Loader show={isLoading} />
      {!isLoading && formData && (  
      // {formData && (  
        <div className={styles.profileContainer}>
          <div className={styles.profileHeader}>
            <h2>Personal Information</h2>
          </div>

          <Button text="Edit" clickFunction={handleEditClick} additionalClass={styles.editButton} />
          <div className={styles.profileDetails}>
            <div className={styles.profileRow}>
              <div className={styles.icon}><CgProfile /></div>
            </div>

            <div className={styles.profileRow}>
              <label>Username:</label>
              <span>{formData.username}</span>
            </div>

            <div className={styles.profileRow}>
              <label>Email:</label>
              <span>{formData.email}</span>
            </div>

            <div className={styles.profileRow}>
              <label>First Name:</label>
              <span>{formData.first_name}</span>
            </div>

            <div className={styles.profileRow}>
              <label>Last Name:</label>
              <span>{formData.last_name}</span>
            </div>

            <div className={styles.profileRow}>
              <label>User Type:</label>
              <span>{formData.is_superuser ? "Admin" : "Standard"}</span>
            </div>

            <div className={styles.profileRow}>
              <label>MFA Status:</label>
              <span>{formData.mfa_enabled ? "Enabled" : "Disabled"}</span>
            </div>
          </div>

          <Button clickFunction={handleResetPassword} text="Reset Password" additionalClass={styles.resetButton} />

          {isEditing && (
            <CustomModal
              title="Update User"
              additionalClass={styles.modalWrap}
              clickFunction={handleCloseModal}
              body={
                <UserForm
                  closePopup={handleCloseModal}
                  modalView="editView"
                  editData={formData}
                  hideFields={{ userType: true, password: true }} 
                  onUpdate={handleUpdateUser} 
                  // setLoading={setLoading} 
                />
              }
            />
          )}
          {isReset && (
            <CustomModal
              title="Reset Password"
              additionalClass={styles.resetModal}
              clickFunction={handleCloseReset}
              body={
                <ResetPassword 
                  isProfile={true}
                  closePopup={handleCloseReset}
                  onPasswordUpdate={handleUpdatePassword}
                  // setLoading={setLoading} 
                />
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserProfile;
