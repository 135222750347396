import { useDispatch } from "react-redux";
import { updateUserActionCreator } from "../../redux/slices/UserSlices";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import InputFields from "../../components/inputFields/InputFields";
import Button from "../../components/button/Button";
import style from "./ResetPasswordModal.module.scss";

const ResetPasswordModal = ({ userData, closePopup }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const passwordValidationRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const onSubmit = (data) => {
    const updatedUserData = {
      body: {
        username: userData.username,
        first_name: userData.firstname,
        last_name: userData.lastname,
        email: userData.email,
        password: data.password,
        is_superuser: userData.role?.value,
        id: userData.id,
      },
    };
    editUser(updatedUserData);
  };

  const editUser = async (data) => {
    let response;
    response = await dispatch(updateUserActionCreator(data));
    if (response.error) {
      // handleError(response);
    } else {
      closePopup();
    }
  };

  return (
    <div className={style.modalContainer}>
      <div className={style.modalHeader}>
        Please enter the new password for the user
      </div>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.fieldContainer}>
          <Controller
            control={control}
            name="password"
            rules={{
              required: "New Password is required",
              validate: {
                matchesPattern: (value) =>
                  passwordValidationRegex.test(value) ||
                  "Password must include uppercase, lowercase, number, and special character.",
              },
            }}
            render={({ field }) => (
              <InputFields
                {...field}
                type={showPassword ? "text" : "password"}
                label="Password"
                name="password"
                placeholder="Enter password"
                errors={errors.password}
                showPasswordToggle={true}
                onTogglePassword={() => setShowPassword(!showPassword)}
                showPassword={showPassword}
              />
            )}
          />
        </div>
        <div className={style.fieldContainer}>
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: "Confirm new password is required.",
              validate: (value) =>
                value === getValues("password") ||
                "New password and confirm password do not match.",
            }}
            render={({ field }) => (
              <InputFields
                {...field}
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                name="confirmPassword"
                placeholder="Enter password"
                errors={errors.confirmPassword}
                showPasswordToggle={true}
                onTogglePassword={() =>
                  setShowConfirmPassword(!showConfirmPassword)
                }
                showPassword={showConfirmPassword}
              />
            )}
          />
        </div>
        <div className={style.buttonContainer}>
          <Button
            text={"Reset Password"}
            clickFunction={handleSubmit(onSubmit)}
          />
          <Button
            text={"Cancel"}
            additionalClass={"border-btn"}
            clickFunction={closePopup}
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordModal;
