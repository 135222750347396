// eslint-disable-next-line no-undef
export const BASIC_API_URL = process.env.REACT_APP_API_URL;
const SERVER_URL = {
  LOGIN: `${BASIC_API_URL}/users/login/`,
  USER_LIST: `${BASIC_API_URL}/users/list/`,
  USER_PROFILE: `${BASIC_API_URL}/users/profile/`,
  CREATE_USER: `${BASIC_API_URL}/users/register/`,
  UPDATE_USER: `${BASIC_API_URL}/users/update/`,
  DELETE_USER: `${BASIC_API_URL}/users/delete/`,
  RESET_PASSWORD: `${BASIC_API_URL}/users/change-password/`,
  MFA_STATUS: `${BASIC_API_URL}/users/mfa/status/`,
  MFA_REGISTER: `${BASIC_API_URL}/users/mfa/setup/`,
  MFA_VERIFY: `${BASIC_API_URL}/users/mfa/verify/`,
  MFA_REMOVE: `${BASIC_API_URL}/users/mfa/disable/`,
  USER_VERIFY: `${BASIC_API_URL}/users/verify-user/`,
  LOGOUT: `${BASIC_API_URL}/users/logout/`,
  UPDATE_PROFILE: `${BASIC_API_URL}/users/profile/update/`,
};

export const role = [
  {
    id: 1,
    label: "Admin",
    value: true,
  },
  {
    id: 2,
    label: "Standard",
    value: false,
  },
];
export const status = [
  {
    id: 1,
    label: "Active",
    value: true,
  },
  {
    id: 2,
    label: "Inactive",
    value: false,
  },
];

export default SERVER_URL;
