import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  loading: false,
  status: "",
  error: "",
};

export const changePasswordActionCreator = createAsyncThunk(
  "users/change-password",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.RESET_PASSWORD}`;
      const { data } = await fetchUtility("put", API_URL, params.body, true);
      return data;
    } catch (error) {
      if (error.response?.data?.new_password?.[0]) {
        return rejectWithValue(
          error.response?.data?.new_password?.[0] ||
            "New password cannot be the same as the current password.",
        );
      } else if (error.response?.data?.current_password?.[0]) {
        return rejectWithValue(
          error.response?.data?.current_password?.[0] ||
            "Current password is incorrect.",
        );
      } else {
        return rejectWithValue(
          error.message || "An error occurred while updating the password",
        );
      }
    }
  },
);

const changePasswordSlice = createSlice({
  name: "change-password",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changePasswordActionCreator.pending, (state) => {
      state.loading = true;
      state.error = "";
      state.status = "";
    });
    builder.addCase(changePasswordActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload.message || "Password changed successfully!";
      state.error = "";
    });
    builder.addCase(changePasswordActionCreator.rejected, (state, action) => {
      state.loading = false;
      // Set the extracted error message in the state
      state.error = action.payload || action.error.message;
      state.status = "";
    });
  },
});

export default changePasswordSlice.reducer;
