import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUserActionCreator,
  updateUserActionCreator,
  updateProfileCreator,
} from "../../redux/slices/UserSlices";
import InputFields from "../../components/inputFields/InputFields";
import SelectField from "../../components/inputFields/SelectField";
import { role } from "../../constants/constants";
import Button from "../../components/button/Button";
import CustomModal from "../../components/customModal/CustomModal";
import { toast } from "react-toastify";
import Success from "../../assets/icons/success.svg";
import style from "./UserForm.module.scss";
import ToastContent from "../../components/toast/ToastContent";

const UserForm = ({ closePopup, modalView, editData, hideFields, onUpdate }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const isLoading = useSelector((state) => state.user.loading);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      username: "",
      role: role.find((r) => r.value === true),
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      id: "",
    },
  });

  useEffect(() => {
    if (modalView === "editView") {
      setValue("firstname", editData?.first_name);
      setValue("lastname", editData?.last_name);
      setValue("email", editData?.email);
      setValue("username", editData?.username);
      setValue(
        "role",
        role.find((r) => r.value === editData?.is_superuser),
      );
      setValue("id", editData?.id);
    }
  }, [modalView, editData, setValue]);

  const handleError = (response) => {
    const errorMessage = response.payload.includes("username")
      ? "A user with that username already exists."
      : response.payload.includes("email")
        ? "A user with that email already exists."
        : response.payload.includes("Password")
          ? response.payload
          : "";
    setError(
      response.payload.includes("username")
        ? "username"
        : response.payload.includes("email")
          ? "email"
          : "password",
      {
        type: "manual",
        message: errorMessage,
      },
    );
  };

  const updateProfile = async (data) => {
    let response;
    response = await dispatch(updateProfileCreator(data));
    if(response.error) {
      handleError(response);
    } else {
      closePopup();
      onUpdate(data.body);
    }
  }

  const addUser = async (data) => {
    let response;
    if (modalView !== "editView") {
      response = await dispatch(saveUserActionCreator(data));
    } else {
      response = await dispatch(updateUserActionCreator(data));
    }
    if (response.error) {
      handleError(response);
    } else {
      if (modalView !== "editView") {
        setUserInfo({
          username: data.body.username,
          password: data.body.password,
        });
        setUserCreated(true);
      } else {
        closePopup();
      }
    }
  };

  const onSubmit = (data) => {
    const userData = {
      body: {
        username: data.username,
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        password: data.password,
        is_superuser: data.role?.value,
        id: data.id || undefined,
      },
    };
    if(!hideFields) {
      addUser(userData);
    } else {
      updateProfile(userData);
    }
  };

  const clearForm = () => {
    reset();
    setUserInfo({});
    setUserCreated(false);
  };

  const copyToClipboard = () => {
    toast.success(
      <ToastContent title="Success" description="Copied to clipboard" />,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        icon: () => <img src={Success} />,
      },
    );
    if (userInfo.username && userInfo.password) {
      navigator.clipboard.writeText(
        `Username: ${userInfo.username}\nPassword: ${userInfo.password}`,
      );
      closePopup();
    }
  };

  return (
    <div className={style.userFormWrap}>
      {!userCreated && ( 
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={style.form}
          noValidate
        >
          <div className={style.formSection}>
            <div>
              <Controller
                control={control}
                name="id"
                render={({ field }) => <InputFields {...field} type="hidden" />}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="firstname"
                rules={{
                  required: "First Name is required",
                  minLength: { value: 3, message: "Minimum 3 characters" },
                  maxLength: { value: 30, message: "Maximum 30 characters" },
                }}
                render={({ field }) => (
                  <InputFields
                    {...field}
                    label="First Name*"
                    type="text"
                    errors={errors.firstname}
                    title="Enter the first name"
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="lastname"
                rules={{
                  required: "Last Name is required",
                  minLength: { value: 3, message: "Minimum 3 characters" },
                  maxLength: { value: 30, message: "Maximum 30 characters" },
                }}
                render={({ field }) => (
                  <InputFields
                    {...field}
                    label="Last Name*"
                    type="text"
                    errors={errors.lastname}
                    title="Enter the last name"
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="username"
                rules={{
                  required: "Username is required",
                  minLength: { value: 3, message: "Minimum 3 characters" },
                  maxLength: { value: 20, message: "Maximum 20 characters" },
                }}
                render={({ field }) => (
                  <InputFields
                    {...field}
                    label="Username*"
                    type="text"
                    errors={errors.username}
                    title="Enter the username"
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Enter a valid email address",
                  },
                }}
                render={({ field }) => (
                  <InputFields
                    {...field}
                    label="Email*"
                    type="email"
                    errors={errors.email}
                    title="Enter the email"
                  />
                )}
              />
            </div>
            {!hideFields?.userType && (
            <div>
              <Controller
                control={control}
                name="password"
                rules={{
                  required: modalView !== "editView" && "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
                    message:
                      "Password must be at least 8 characters, include uppercase, lowercase, number, and special character",
                  },
                }}
                render={({ field }) => (
                  <InputFields
                    {...field}
                    label="Password*"
                    type={showPassword ? "text" : "password"}
                    showPasswordToggle
                    onTogglePassword={() => setShowPassword(!showPassword)}
                    errors={errors.password}
                    title="Enter the password"
                  />
                )}
              />
            </div>
            )}
            {!hideFields?.userType && (
            <div>
              <Controller
                control={control}
                name="role"
                rules={{ required: "Role is required" }}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    label="User Type*"
                    option={role}
                    errors={errors.role}
                    ariaLabel="Select the role"
                  />
                )}
              />
            </div>
            )}
          </div>

          <div className={style.fixedButtonContainer}>
            <button
              type="button"
              onClick={clearForm}
              className={style.clearButton}
            >
              Clear form
            </button>
            <Button
              text={modalView === "editView" ? "Update User" : "Create User"}
              additionalClass={`${modalView === "editView" ? style.updateUser : style.createUser}`} 
              loader={isLoading} 
            />
          </div>
        </form>
      )}

      {userCreated && (
        <CustomModal
          title="User Created"
          additionalClass={style.userWrap}
          clickFunction={() => setUserCreated(false)}
          body={
            <div className={style.userContainer}>
              <div className={style.userInfoContent}>
                <p className={style.message}>
                  Please note Username & Password; you will not be able to view
                  the password after closing this reminder.
                </p>
                <p>
                  <strong>Username:</strong> {userInfo.username}
                </p>
                <p>
                  <strong>Password:</strong> {userInfo.password}
                </p>
              </div>
              <Button
                text="Copy"
                clickFunction={copyToClipboard}
                additionalClass={style.copyBtn}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default UserForm;
