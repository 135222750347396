import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Button from "../../../components/button/Button";
import InputFields from "../../../components/inputFields/InputFields";
import style from "./ResetPassword.module.scss";
import { changePasswordActionCreator } from "../../../redux/slices/ChangePasswordSlices";

const ResetPassword = ({ isProfile, closePopup, onPasswordUpdate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isLoading = useSelector((state) => state.changePassword.loading);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  useEffect(() => {
    Cookies.set("skip_alert", true);
  }, []);

  const passwordValidationRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const resetPassword = (data) => {
    // setLoading(true);
    dispatch(changePasswordActionCreator(data)).then((response) => {
        if (response.error) {
            if (response.payload.includes("Current")) {
            setError("oldPassword", {
                type: "manual",
                message: "Current password is incorrect.",
            });
            } else if (response.payload.includes("New password")) {
            setError("newPassword", {
                type: "manual",
                message: "New password cannot be the same as the current password.",
            });
            }
        } else {
            if(isProfile) {
                onPasswordUpdate();
                closePopup();
            } else {
                navigate("/");
            }
        }
    });
  };

  const onSubmit = (data) => {
    resetPassword({
      body: {
        current_password: data.oldPassword,
        new_password: data.newPassword,
      },
    });
  };

  return (
    <div className={style.resetPassword} >
        <div className={style.formSection}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <div>
                        <Controller
                        control={control}
                        name="oldPassword"
                        rules={{ required: "Current Password is required" }}
                        render={({ field }) => (
                            <InputFields
                            {...field}
                            type={showOldPassword ? "text" : "password"}
                            label="Current Password"
                            name="oldPassword"
                            placeholder="Enter current password"
                            errors={errors.oldPassword}
                            showPasswordToggle={true}
                            onTogglePassword={() =>
                                setShowOldPassword(!showOldPassword)
                            }
                            showPassword={showOldPassword}
                            title="Enter the current password"
                            />
                        )}
                        />
                    </div>
                    <div>
                        <Controller
                        control={control}
                        name="newPassword"
                        rules={{
                            required: "New Password is required",
                            validate: {
                            matchesPattern: (value) =>
                                passwordValidationRegex.test(value) ||
                                "Password must include uppercase, lowercase, number, and special character.",
                            },
                        }}
                        render={({ field }) => (
                            <InputFields
                            {...field}
                            type={showNewPassword ? "text" : "password"}
                            label="New Password"
                            name="newPassword"
                            placeholder="Enter new password"
                            errors={errors.newPassword}
                            showPasswordToggle={true}
                            onTogglePassword={() =>
                                setShowNewPassword(!showNewPassword)
                            }
                            showPassword={showNewPassword}
                            title="Enter the new password"
                            />
                        )}
                        />
                    </div>
                    <div>
                        <Controller
                        control={control}
                        name="confirmNewPassword"
                        rules={{
                            required: "Confirm new password is required.",
                            validate: (value) =>
                            value === getValues("newPassword") ||
                            "New password and confirm password do not match.",
                        }}
                        render={({ field }) => (
                            <InputFields
                            {...field}
                            type={showConfirmPassword ? "text" : "password"}
                            label="Confirm New Password"
                            name="confirmNewPassword"
                            placeholder="Re-enter new password"
                            errors={errors.confirmNewPassword}
                            showPasswordToggle={true}
                            onTogglePassword={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                            }
                            showPassword={showConfirmPassword}
                            title="Re-enter the new password"
                            />
                        )}
                        />
                    </div>
                </div>
                <div className={style.changePasswordBtn}>
                    <Button text="Reset Password" loader={isLoading} />
                </div>
            </form>
        </div>
    </div>
  );
};

export default ResetPassword;
