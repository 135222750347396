import { useState, useMemo, Fragment } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useFilters,
} from "react-table";
import InputField from "../inputFields/InputFields";

import CheckBox from "./CheckBox";
import "./Table.scss";

import SortAscIcon from "../../assets/icons/sort-icon-asc.svg";
import SortDescIcon from "../../assets/icons/sort-icon-desc.svg";

function ColumnFilter({ column: { filterValue, setFilter, options } }) {
  const [value, setValue] = useState(filterValue || "");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setFilter(value || undefined); // Set undefined to remove the filter entirely
    }
  };

  return (
    <>
      {options ? (
        <select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Select...</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <InputField
          value={value}
          onChange={(e) => setValue(e.target.value)} // Update the local state
          handleKeyDown={handleKeyDown} // Trigger filtering on Enter key press
          placeholder={`Search`}
        />
      )}
    </>
  );
}

const Table = ({
  columns,
  enableSelect = false,
  groupColumn = [],
  showPaginations = false,
  dataList,
  isFilterEnabled = true,
}) => {
  const dataColumns = useMemo(() => columns, [columns]);
  const datas = useMemo(() => dataList, [dataList]);
  const defaultColumn = useMemo(
    () => ({
      // Set up a default Filter UI
      Filter: ColumnFilter,
    }),
    [],
  );

  const tableData = useTable(
    {
      columns: dataColumns,
      data: datas,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        groupBy: groupColumn, // Or any column you want to group by
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (enableSelect) {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "Selection",
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return <CheckBox {...getToggleAllRowsSelectedProps()} />;
              },
              Cell: ({ row }) => {
                return <CheckBox {...row.getToggleRowSelectedProps()} />;
              },
            },
            ...columns,
          ];
        });
      }
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    // selectedFlatRows,
    prepareRow,
    state,
  } = tableData;

  const { pageIndex, pageSize } = state;
  return (
    <>
      <div className="common-table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
              return (
                <Fragment key={key}>
                  <tr {...headerProps}>
                    {headerGroup.headers.map((column) => {
                      const { key, ...columnProp } = column.getHeaderProps(
                        column.getSortByToggleProps(),
                      );
                      return (
                        !column.isGrouped && (
                          <th key={key} {...columnProp}>
                            <div className="table-head">
                              {column.render("Header")}
                              {column.canSort && (
                                <span className="sort-icons">
                                  <button
                                    onClick={() => column.toggleSortBy(false)}
                                    style={{
                                      border: "none",
                                      background: "none",
                                    }}
                                  >
                                    <img
                                      src={SortAscIcon}
                                      alt="sort-asc-icon"
                                    />
                                  </button>
                                  <button
                                    onClick={() => column.toggleSortBy(true)}
                                    style={{
                                      border: "none",
                                      background: "none",
                                    }}
                                  >
                                    <img
                                      src={SortDescIcon}
                                      alt="sort-desc-icon"
                                    />
                                  </button>
                                </span>
                              )}
                            </div>
                          </th>
                        )
                      );
                    })}
                  </tr>
                  {/* Add a row for filters after the header row */}
                  {isFilterEnabled && (
                    <tr className="filter-row">
                      {headerGroup.headers.map((column) => (
                        <th key={column.id}>
                          {/* Render the column filter UI */}
                          {column.canFilter ? column.render("Filter") : null}
                        </th>
                      ))}
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {(showPaginations ? page : rows).map((row) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();
              return (
                <tr key={key} {...rowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restProps } = cell.getCellProps();
                    return (
                      !cell.column.isGrouped && (
                        <td key={key} {...restProps}>
                          {cell.render("Cell")}
                        </td>
                      )
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showPaginations && (
        <div className="pagination">
          <div>
            <span>
              Show{" "}
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSizeval) => (
                  <option key={pageSizeval} value={pageSizeval}>
                    {pageSizeval}
                  </option>
                ))}
              </select>{" "}
              entries
            </span>
          </div>
          <div>
            <span>
              Showing{"  "}
              <strong>{`${pageIndex + 1} of ${pageOptions.length}`}</strong>
            </span>
          </div>
          <div>
            <button
              className="arrows"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >{`<<`}</button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {`<`}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {`>`}
            </button>
            <button
              className="arrows"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >{`>>`}</button>
          </div>
        </div>
      )}
      {/* <div style={{ display: "none" }}>
        {JSON.stringify({
          selectedRows: selectedFlatRows.map((data) => data.original),
        })}
      </div> */}
    </>
  );
};

export default Table;
