import Dashboard from "./pages/dashboard/Dashboard";
import UserList from "./pages/users/UserList";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import AccessDenied from "./pages/auth/access-denied/AccessDenied";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import Login from "./pages/auth/login/Login";
import UserProfile from "./pages/users/UserProfile";

export const routes = [
  {
    path: "/profile",
    name: "profile",
    component: <UserProfile />,
    allowedRoles: true,
  },
  {
    path: "/user",
    name: "Users",
    component: <UserList />,
    allowedRoles: true,
  },
];
export const commonRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
  },
  {
    path: "/",
    name: "",
    component: <Dashboard />,
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: <AccessDenied />,
  },
];
export const authroutes = [
  {
    path: "/login",
    name: "Login",
    component: <Login />,
    isLogged: false,
  },
  {
    path: "/forgot-password",
    name: "",
    component: <ForgotPassword />,
    isLogged: false,
  },
  {
    path: "/update-password",
    name: "ChangePassword",
    component: <ChangePassword />,
    isLogged: true,
  },
];
