import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  loading: false,
  status: "",
  error: "",
};

// Action creator for verifying user
export const forgotPasswordActionCreator = createAsyncThunk(
  "users/verify-user",
  async (params) => {
    const API_URL = `${SERVER_URL.USER_VERIFY}`; // Ensure this points to the correct endpoint
    const { data } = await fetchUtility("post", API_URL, params.body, false);
    return data;
  },
);

const forgotPasswordSlice = createSlice({
  name: "forgot-password",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(forgotPasswordActionCreator.pending, (state) => {
      state.loading = true;
      state.error = "";
      state.status = "";
    });
    builder.addCase(forgotPasswordActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload.message || "User verified successfully!";
      state.error = "";
    });
    builder.addCase(forgotPasswordActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = "";
    });
  },
});

export default forgotPasswordSlice.reducer;
