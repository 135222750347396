import { useDispatch } from "react-redux";
import style from "./UserList.module.scss";
import Button from "../../components/button/Button";
import {
  deleteUserActionCreator,
  updateUserActionCreator,
} from "../../redux/slices/UserSlices";
import { removeMFAActionCreator } from "../../redux/slices/LoginSlices";

const ConfirmModal = ({ userData, confirmationType, closePopup }) => {
  const dispatch = useDispatch();

  const getConfirmationText = () => {
    if (confirmationType === "delete") {
      return "delete";
    } else if (confirmationType === "deactivate") {
      return userData.is_active ? "activate" : "deactivate";
    } else if (confirmationType === "disableMFA") {
      return "disable MFA for";
    }
  };

  const handleConfirm = () => {
    if (confirmationType === "delete") {
      dispatch(deleteUserActionCreator(userData?.id)).then(() => {
        closePopup();
      });
    } else if (confirmationType === "deactivate") {
      dispatch(updateUserActionCreator(userData)).then((response) => {
        if (!response.error) {
          closePopup();
        }
      });
    } else if (confirmationType === "disableMFA") {
      dispatch(removeMFAActionCreator(userData?.id)).then(() => {
        closePopup();
      });
    }
  };

  return (
    <div className={style.confirmModal}>
      <h4>Do you want to {getConfirmationText()} the user?</h4>
      <div className={style.confirmBtns}>
        <Button text={"Confirm"} clickFunction={handleConfirm} />
        <Button
          text={"Cancel"}
          additionalClass={"border-btn"}
          clickFunction={() => closePopup()}
        />
      </div>
    </div>
  );
};

export default ConfirmModal;
