import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Error from "../../../assets/icons/error.svg";
import Button from "../../../components/button/Button";
import logo from "../../../assets/logo/hines.svg";
import InputFields from "../../../components/inputFields/InputFields";
import { QRCodeSVG } from "qrcode.react";
import style from "./Login.module.scss";
import {
  // getLoginActionCreator,
  getUserDetailActionCreator,
  getMFAStatusActionCreator,
  registerMFAActionCreator,
  verifyMFAActionCreator,
} from "../../../redux/slices/LoginSlices";
import LeftImageBlock from "../../../components/leftImageBlock/LeftImageBlock";
import ToastContent from "../../../components/toast/ToastContent";

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [credError, setCredError] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [mfaError, setMFAError] = useState(false);
  const loginErr = useSelector((state) => state.login.error);
  const [showPassword, setShowPassword] = useState(false);
  const [isPageExpire, setIsPageExpire] = useState(false);
  const isLoading = useSelector((state) => state.login.loading);
  const [rememberMe, setRememberMe] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      username: "",
      password: "",
      mfaCode: "",
    },
  });
  const navigate = useNavigate();

  const sessionTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds

  useEffect(() => {
    if (showMFA) {
      const timer = setTimeout(() => {
        Cookies.remove("user");
        Cookies.remove("token");
        setShowMFA(false);
        setIsPageExpire(true);
        toast.error(
          <ToastContent
            title="Error"
            description={"Your session has expired due to inactivity"}
          />,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            icon: () => <img src={Error} />,
          },
        );
      }, sessionTimeout);

      return () => clearTimeout(timer);
    }
  }, [navigate, sessionTimeout, showMFA]);

  const getLoginData = (data) => {
    dispatch(getMFAStatusActionCreator(data)).then((response) => {
      if (response?.payload) {
        if (response?.payload?.mfa_enabled) {
          setShowMFA(true);
        } else {
          dispatch(registerMFAActionCreator(data)).then((response) => {
            if (response) {
              setShowMFA(true);
              setQrCode(response.payload.mfa_uri);
            }
          });
        }
      }
    });
  };

  const verifyMFA = (data) => {
    dispatch(verifyMFAActionCreator(data)).then((response) => {
      if (response?.payload?.access) {
        if (response?.payload?.is_new) {
          Cookies.set("skip_alert", false);
        }
        dispatch(getUserDetailActionCreator()).then((response) => {
          if (response) {
            navigate("/dashboard");
          }
        });
        setMFAError(false);
      } else {
        setMFAError(true);
      }
    });
  };

  const onSubmit = (data) => {
    if (data?.mfaCode) {
      if (data?.mfaCode.length > 6) {
        setError("mfaCode", {
          type: "manual",
          message: "Maximum 6 digits required",
        });
        return;
      } else if (data?.mfaCode.length < 6) {
        setError("mfaCode", {
          type: "manual",
          message: "Minimum 6 digits required",
        });
        return;
      }
    }
    setIsPageExpire(false);
    if (showMFA) {
      verifyMFA({
        body: {
          username: data.username,
          password: data.password,
          otp_code: data.mfaCode,
          remember_me: rememberMe,
        },
      });
    } else {
      getLoginData({
        body: { username: data.username, password: data.password },
      });
    }
  };

  return (
    <div className={style.logIn}>
      <div className={style.innerContent}>
        <LeftImageBlock />
        <div className={style.formSection}>
          <div>
            <div className={style.logoContainer}>
              <img src={logo} alt="hines logo" className={style.brandLogo} />
            </div>
            <div>
              {showMFA && !!qrCode && (
                <div className={style.qrCodeImage}>
                  <p className={style.qrCodeText}>
                    Scan the QR code to link your account with the Authenticator
                    app.
                  </p>
                  <QRCodeSVG value={qrCode} size={130} />
                </div>
              )}
              <p className={style.welcomeText}>
                {showMFA
                  ? "Enter the 6-digit verification code generated by your authentication app."
                  : "Welcome to the Hines Budgeting Tool! This portal is for current Hines investors."}
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                {!showMFA && (
                  <>
                    <div className={style.fieldContainer}>
                      <Controller
                        control={control}
                        name="username"
                        rules={{ required: "Username is required" }}
                        render={({ field }) => (
                          <InputFields
                            {...field}
                            type="text"
                            label="Username"
                            name="username"
                            placeholder="Enter username"
                            errors={errors.username}
                            title="Enter the username"
                          />
                        )}
                      />
                    </div>
                    <div className={style.fieldContainer}>
                      <Controller
                        control={control}
                        name="password"
                        rules={{ required: "Password is required" }}
                        render={({ field }) => (
                          <InputFields
                            {...field}
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            placeholder="Enter password"
                            errors={errors.password}
                            showPasswordToggle={true}
                            onTogglePassword={() =>
                              setShowPassword(!showPassword)
                            }
                            showPassword={showPassword}
                            title="Enter the password"
                          />
                        )}
                      />
                    </div>
                    <div className={style.checkboxRow}>
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        className={style.checkbox}
                      />
                      <label
                        htmlFor="rememberMe"
                        className={style.checkboxLabel}
                      >
                        Remember Me
                      </label>
                    </div>
                  </>
                )}
                {!(isPageExpire || location?.state?.message) &&
                loginErr &&
                !showMFA ? (
                  <p style={{ color: "#B70021" }}>
                    Incorrect username or password
                  </p>
                ) : (
                  <></>
                )}
                {showMFA && (
                  <Controller
                    control={control}
                    name="mfaCode"
                    rules={{
                      required: "Verification code is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only digits are allowed",
                      },
                    }}
                    render={({ field }) => (
                      <InputFields
                        {...field}
                        type="text"
                        label="Verification Code"
                        name="mfaCode"
                        placeholder="Enter code"
                        errors={errors.mfaCode}
                        title="Enter the verification code"
                      />
                    )}
                  />
                )}
                {mfaError && showMFA && !errors?.mfaCode && (
                  <p style={{ color: "red" }}>Invalid MFA code</p>
                )}
                <div className={style.signInBtn}>
                  <Button
                    text={showMFA ? "Verify" : "Sign in"}
                    loader={isLoading}
                  />
                </div>
              </form>
              <div className={style.helpSection}>
                <p className={style.helpText}>Need Help?</p>
                <a
                  href="mailto:InvestorRelations@hines.com"
                  className={style.helpEmail}
                >
                  InvestorRelations@hines.com
                </a>
                <p className={style.contactLink}>
                  If you require access, please contact us.{" "}
                  <a href="mailto:InvestorRelations@hines.com">Hines</a>
                </p>
                <p className={style.hinesText}>© 2024 Hines</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
